import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component'
import { ContactoModule } from './contacto/contacto.module'
import { SharedModule } from './shared/shared.module'
import { AvisosPrivacidadComponent } from './avisos-privacidad/avisos-privacidad.component'
import { FormsModule } from '@angular/forms'
import { AuthModule } from '@inmueble/auth'
import { AdministradorComponent } from './administrador/administrador.component'
import { InicioSesionComponent } from './inicio-sesion/inicio-sesion.component'
import { StoreModule } from '@ngrx/store'
import { pos } from '../../../pos/src/app/store/pos.reducer'

const routes: Routes = [
  {
    path: 'servicios',
    loadChildren: () => import('./servicios/servicios.module').then((m) => m.ServiciosModule),
  },
  {
    path: 'avisos-de-privacidad',
    component: AvisosPrivacidadComponent,
  },
  {
    path: 'inicio-sesion',
    component: InicioSesionComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: '',
    loadChildren: () => import('./inicio/inicio.module').then((m) => m.InicioModule),
  },
]

@NgModule({
  declarations: [AppComponent, AvisosPrivacidadComponent, AdministradorComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    SharedModule,
    StoreModule.forRoot({ pos }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ContactoModule,
    AuthModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
