<div class="contenido" >
  <button class="boton" (click)="toggle($event)">
    <svg fill="currentColor" viewBox="0 0 20 20" width="20" height="20" *ngIf="!isOpen">
      <path
        fill-rule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
        clip-rule="evenodd"
      ></path>
    </svg>
    <svg fill="currentColor" viewBox="0 0 20 20" width="20" height="20" *ngIf="isOpen">
      <path
        fill-rule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clip-rule="evenodd"
      ></path>
    </svg>
  </button>
  <rcco-navbar-menu class="menu" (click)="toggle($event)"></rcco-navbar-menu>
</div>
