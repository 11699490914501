import { Component, OnInit } from '@angular/core'
import { IUsuarioLogin } from '../../../../../../libs/auth/types'
import { AuthSesionLoginService } from '../../../../../../libs/auth/src/lib/sesion/apis/login.service'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'

@Component({
  selector: 'rcco-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss'],
})
export class NavbarMenuComponent implements OnInit {
  menuDark = false
  routeAdministrador = ['dashboard', 'blogs/administrador', 'suscripciones/administrador']
  modalIniciaSesion = false
  menuMovil = false
  user: IUsuarioLogin | null = null
  movil!: boolean

  constructor(private authSesion: AuthSesionLoginService, private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(({ url }: any) => {
      this.menuDark = this.routeAdministrador.some((i) => url.includes(i))
    })
    if (this.authSesion.isAuth()) {
      this.sessionAuth()
    }
  }

  logout(): void {
    this.authSesion.unauth()
    this.user = null
  }

  sessionAuth(): void {
    this.user = null
    if (this.authSesion.isAuth()) {
      this.authSesion.current().subscribe((data: IUsuarioLogin) => (this.user = data))
    }
  }

  menuOpen() {
    console.log('alllou')
    if (this.movil) {
      const body = document.getElementById('body')
      this.menuMovil = !this.menuMovil
      body.style.position = this.menuMovil ? 'fixed' : 'unset'
    }
  }

  modal() {
    this.modalIniciaSesion = false
    this.sessionAuth()
  }
}
