import { ChangeDetectionStrategy, Component } from '@angular/core'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faClock, faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'
import { combineLatest, map } from 'rxjs'
import { DataService } from '../shared/data.service'

const iconos = {
  correo: faEnvelopeOpen,
  horario: faClock,
  facebook: faFacebook,
  instagram: faInstagram,
}

@Component({
  selector: 'rcco-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactoComponent {
  public iconos = iconos

  public data = combineLatest([this.dataService.contacto(), this.dataService.redesSociales()]).pipe(
    map(([contacto, redesSociales]) => ({ contacto, redesSociales })),
  )

  public contacto = this.dataService.contacto()

  public redesSociales = this.dataService.redesSociales()

  constructor(private dataService: DataService) {}
}
