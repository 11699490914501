import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IVerficacion } from '../../types';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class VerificarService {

  constructor(private $http: HttpClient) {
  }

  post(data: any): Observable<object> {
    return this.$http.post(`/api/auth/usuarios/usuario`, data);
  }

  get(): Observable<object> {
    return this.$http.get(`/api/usuarios/usuario/verificar`);
  }
}
