import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import * as permisosAction from '../store/permisos.action'
import * as permisosSelector from '../store/permisos.selectors'
import { ISessionModulosPermisos } from '../../../../types';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthSesionPermisosService {
  constructor(private store: Store<{ data: any }>) {}
  cargar$(data: ISessionModulosPermisos): void {
    this.store.dispatch(permisosAction.cargarPermisos({ data }))
  }

  obtener$() : Observable<ISessionModulosPermisos> {
    return this.store.select(permisosSelector.get).pipe()
  }
}
