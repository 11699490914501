import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { ICredecial, IUsuarioLogin } from '../../../../../libs/auth/types'
import { AuthSesionLoginService } from '../../../../../libs/auth/src/lib/sesion/apis/login.service'
import { Router } from '@angular/router'
import { catchError, map } from 'rxjs/operators'
import { of } from 'rxjs'

@Component({
  selector: 'rcco-inicio-sesion',
  templateUrl: './inicio-sesion.component.html',
  styleUrls: ['./inicio-sesion.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class InicioSesionComponent implements OnInit {
  @Output() cierrate = new EventEmitter<void>()
  procesando: boolean
  error = null
  respuesta: any
  credencial: ICredecial
  user: IUsuarioLogin | null = null
  form: unknown

  constructor(private sesion: AuthSesionLoginService, private route: Router) {
    this.procesando = false
    this.credencial = { username: '', password: '' }
  }

  ngOnInit(): void {}

  aceptar(): void {
    if (this.procesando) {
      return
    }

    this.error = null
    this.procesando = true

    this.sesion
      .auth(this.credencial)
      .pipe(
        map((response) => {
          this.respuesta = response
          this.procesando = false
          this.sesion.setSession(response)
          this.verificar()
          return response
        }),
        catchError(({ error }) => {
          this.procesando = false
          this.error = error.error || error
          return of(error)
        }),
      )
      .subscribe()
  }

  verificar(): void {
    this.sesion.current().subscribe((data) => {
      this.route.navigate(['/dashboard'])
      this.user = data
      this.cierrate.emit()
    })
  }

  cerrar() {
    this.cierrate.emit()
  }
}
