import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { fromEvent, map, Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class BackToTopService {
  visible: Observable<boolean>

  constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>, @Inject(DOCUMENT) private document: Document) {
    if (isPlatformBrowser(platformId)) {
      const threshold = window.innerHeight * 0.8
      this.visible = fromEvent(window, 'scroll').pipe(
        map(() => document.body.scrollTop > threshold || document.documentElement.scrollTop > threshold),
      )
    } else {
      this.visible = of(false)
    }
  }

  scrollTo(y: number = 0) {
    if (this.document.scrollingElement) {
      this.document.scrollingElement.scrollTop = y
    }
  }
}
