import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'rcco-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AdministradorComponent implements OnInit {
  administrador = false
  constructor() {}

  ngOnInit(): void {}
}
