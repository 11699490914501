import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Route } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { SuscripcionBrowserRoutingModule } from './suscripcion-browser-routing.module'

export const suscripcionBrowserRoutes: Route[] = []

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule, SuscripcionBrowserRoutingModule],
})
export class SuscripcionBrowserModule {}
