<div [ngClass]="{'bg-gray-50': expandir}">
  <div class="w-11/12 py-2 flex h-8 items-center mx-auto rounded cursor-pointer --text-clip text-sm"
       title="{{item.nombre}}"
       [ngClass]="{
           'flex-col h-10 p-0': oculto && !hover,
           'hover:bg-gris-claro': !oculto || hover
           }"
       (click)="item.items.length ? (oculto && !hover ? menuElement.toggle($event) : expandir = !expandir) : navegar(urlPrincipal + item.url);">
    <p-menu #menuElement [model]="menu" [hidden]="!item.items.length" [popup]="true"></p-menu>
    <button class="icon"
            pTooltip="{{item.nombre}}"
            type="button">
      <i class="{{item.icon || 'pi pi-file'}}"></i>
    </button>
    <p class="--text-clip flex-grow" *ngIf="!oculto || hover">{{item.nombre}} </p>
    <button class="icon" type="button" *ngIf="item.items.length && (!oculto || hover)">
      <i class="pi" [ngClass]="{'pi-caret-down': !expandir, 'pi-caret-up': expandir}"></i>
    </button>
  </div>
  <div class="w-11/12 flex flex-col  mx-auto"
       *ngIf="(!oculto || hover) && expandir">
    <div class="w-full flex h-8 py-2 items-center rounded cursor-pointer --text-clip text-sm"
         *ngFor="let elemento of item.items"
         title="{{elemento.descripcion}}"
         [ngClass]="{
           'flex-col h-10 p-0': oculto && !hover,
           'hover:bg-gris-claro': !oculto || hover
           }"
         (click)="navegar(urlPrincipal + elemento.url);">
      <button class="icon"
              pTooltip="{{elemento.descripcion}}"
              type="button">
        <i class="{{elemento.icon || 'fas fa-file'}}"></i>
      </button>
      <p class="--text-clip" *ngIf="!oculto || hover">{{elemento.descripcion}} </p>
    </div>
  </div>

</div>
