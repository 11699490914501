import { createReducer, on } from '@ngrx/store';
import { cargarPermisos, obtenerPermisos } from './permisos.action';

export const initialState = {
  sesion: {
    permisos: [],
  },
}

const permisosReducer = createReducer(
  initialState,
  on(cargarPermisos, (state, { data }) => {
    return {
      ...state,
      sesion: {
        permisos: [...state.sesion.permisos.concat(data)],
      },
    }
  }),

  on(obtenerPermisos, (state) => state),
)

// tslint:disable-next-line:typedef
export function permisos(state, action) {
  return permisosReducer(state, action)
}
