import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { NgForm } from '@angular/forms'
import { SuscripcionSuscribeService } from '../../../../../../libs/suscripcion/browser/src/lib/services/suscribe.service'
import { first } from 'rxjs'
import type { SuscripcionDto } from '@decet/suscripcion-shared'

@Component({
  selector: 'rcco-contacto-suscribete',
  templateUrl: './suscribete.component.html',
  styleUrls: ['./suscribete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuscribeteComponent {
  patron = `^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`
  @Input() correo = ''
  data: SuscripcionDto | null = null
  iconoExito = faCheckCircle
  exito = false
  error: any

  constructor(private suscripcionSuscribeService: SuscripcionSuscribeService) {}

  aceptar(form: NgForm): void {
    this.data = {
      nombre: '',
      correo: this.correo,
      dominioId: 1,
      empresaId: 1,
      busqueda: '',
      activo: true,
    }
    this.suscripcionSuscribeService
      .post(this.data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.exito = true
          this.correo = ''
          form.resetForm()
          setTimeout(() => {
            this.exito = false
          }, 3000)
        },
      })
  }
}
