import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import type { SuscripcionDto } from '@decet/suscripcion-shared'

@Injectable({
  providedIn: 'root',
})
export class SuscripcionSuscribeService {
  constructor(private http: HttpClient) {}

  post(data: SuscripcionDto): Observable<boolean> {
    return this.http.post<boolean>('/api/suscribe', data)
  }
  suscritos(): Observable<any> {
    return this.http.get<any>('api/suscribe')
  }
  unSubscribe(correo: string): Observable<string> {
    return this.http.get<string>(`api/suscribe/unsuscribe/${correo}`)
  }
}
