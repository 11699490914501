import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IReestablecer } from '../../../../types';

@Injectable({
  providedIn: 'root',
})
export class AuthSesionReestablecerService {

  constructor(private $http: HttpClient) {
  }


  post(data: IReestablecer): Observable<{ id: number, enviado: boolean }> {
    return this.$http.post<{ id: number, enviado: boolean }>(`/api/sesion/reestablecer`, data);
  }
}
