<div class="toolbar-container">
  <div class="toolbar-container-header">
    <div class="toolbar-header">
      <div class="__header-title typo-title">Verificar</div>
    </div>
  </div>
</div>
<div class="padding-md">
  <form #form="ngForm"
      *ngIf="!completado"
      class="padding-md reestablecer"
      autocomplete="off"
      (submit)="verificar()">
    <p class="margin-md">
      Para continuar es necesario asignar contraseña y aceptar los terminos y serivicios.
    </p>
    <div class="at-fieldset margin-md">
      <div class="at-control">
        <input class="p-inputtext"
            type="password"
            required
            placeholder="Contraseña"
            pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}"
            name="password"
          #password="ngModel"
            [(ngModel)]="data.password">
      </div>
      <div class="at-control">
        <input class="p-inputtext"
            type="password"
            required
            placeholder="Repite Contraseña"
            pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}"
            name="passwordRepeat"
          #passwordRepeat="ngModel"
            [(ngModel)]="data.passwordRepeat">
      </div>
      <div class="at-control at-fieldset--span-12">
        <p-checkbox [(ngModel)]="data.aceptoTerminosServicio"
          #aceptoTerminosServicio="ngModel"
            name="aceptoTerminosServicio"
            label="Acepto los términos y condiciones"
            [binary]="true"></p-checkbox>
      </div>
      <ul class="at-control_messages" *ngIf="password.errors">
        <li *ngIf="password.errors.required && password.touched">Contraseña requerida</li>
        <li *ngIf="password.errors.pattern && password.touched">Contraseña en formato incorrecto</li>
      </ul>
      <ul class="at-control_messages" *ngIf="passwordRepeat.errors">
        <li *ngIf="passwordRepeat.errors.required && passwordRepeat.touched">Contraseña requerida</li>
        <li *ngIf="passwordRepeat.errors.pattern && passwordRepeat.touched">Contraseña en formato incorrecto</li>
      </ul>
    </div>
    <div class="margin-md --text-error"
        *ngIf="error">
      <div *ngIf="error.error.error">
        {{error.error.error}}
      </div>
      <div *ngIf="!error.error.error">
        {{error.message}}
      </div>
    </div>
    <div class="text-align-right margin-md">
      <button class="p-button p-button-raised"
          type="submit"
          aria-disabled="true"
          [disabled]="form.invalid || procesando">
        Enviar
      </button>
    </div>
  </form>
  <!--p *ngIf="!valido">
    No se encontro al usuario
  </p-->
  <section class="padding-md reestablecer"
      *ngIf="completado">
    <p class="margin-md">
      Se ha registrado correctamente.
    </p>
    <br><br>
    <div class="text-align-right margin-md">
      <a class="p-button p-button-raised" routerLink="/sesion/login">
        Regresar a inicio
      </a>
    </div>
  </section>
</div>
