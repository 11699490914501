import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  {
    path: 'suscripciones/administrador',
    loadChildren: () =>
      import('./lista-administrador/lista-administrador.module').then((m) => m.ListaAdministradorModule),
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuscripcionBrowserRoutingModule {}
