import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthSesionModuloService } from '../../usuarios/apis/modulo.service';
import { ActivatedRoute } from '@angular/router';
import { ISessionModulosPermisos } from '../../../../types';
import { AuthSesionPermisosService } from '../services/permisos.service';

@Component({
  selector: 'auth-sesion-current',
  templateUrl: './current.component.html',
  styleUrls: ['./current.component.scss'],
})
export class AuthCurrentComponent implements OnInit {
  @Output() onSizeChange = new EventEmitter<boolean>()
  @Input() moduloId!: number
  hover = false
  urlPrincipal = ''
  oculto = true
  permisos!: ISessionModulosPermisos
  secciones = []
  items = []

  constructor(
    private routeActived: ActivatedRoute,
    private moduloService: AuthSesionModuloService,
    private permisosService$: AuthSesionPermisosService) {
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.urlPrincipal = this.routeActived._routerState.snapshot.url.split('/')[1]
    this.moduloService.get(this.moduloId).subscribe(data => {
      this.permisos = data
      this.secciones = []
      this.permisos.forEach((item) => {
        if (!item.menu) {
          this.secciones.push({ nombre: item.descripcion, icon: item.icon, items: [], url: item.url })
          return
        }
        const seccion = this.secciones.find((seccion) => seccion.nombre === item.menu)
        if (seccion) {
          seccion.items.push(item)
          seccion.unico = false
        } else {
          this.secciones.push({ nombre: item.menu, icon: item.menuIcon, items: [item] })
        }
      })
      this.permisosService$.cargar$(data)

      console.log(this.secciones, 'seccionesssssssssssssss');
    })


  }

  cambiarTamano(): void {
    this.oculto = !this.oculto
    this.onSizeChange.emit(this.oculto)
  }

}
