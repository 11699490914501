<img src="/assets/img/logotipo-rcco-abogados.png" style="z-index: 999; width: 30%; margin: 0 auto" alt="Logo Decet" />
<nav>
  <a href="#inicio" [ngStyle]="{'color': menuDark ? 'black': ''}">Inicio</a>
  <a href="#nosotros" [ngStyle]="{'color': menuDark ? 'black': ''}">Nosotros</a>
  <a href="#servicios" [ngStyle]="{'color': menuDark ? 'black': ''}">Servicios</a>
  <a href="#contacto" [ngStyle]="{'color': menuDark ? 'black': ''}">Contacto</a>
  <a href="blogs" [ngStyle]="{'color': menuDark ? 'black': ''}">Blogs</a>
  <a href="dashboard" (click)="menuOpen()" *ngIf="user" [ngStyle]="{'color': menuDark ? 'black': ''}">Administrador</a>
  <a href="inicio-sesion" *ngIf="!user" (click)="menuOpen();modalIniciaSesion=true"
     [ngStyle]="{'color': menuDark ? 'black': ''}"> Iniciar Sesión</a>
  <a *ngIf="user" (click)="logout()" [ngStyle]="{'color': menuDark ? 'black': ''}" ><span><i class="pi pi-sign-out"></i></span>{{user.nombre}}
  </a>
</nav>
<rcco-inicio-sesion *ngIf="modalIniciaSesion" (cierrate)="modal()"></rcco-inicio-sesion>
<p-dialog *ngIf="modalIniciaSesion" [showHeader]="true" [header]="'Iniciar sesión'">
  <div>GOLA</div>
</p-dialog>
