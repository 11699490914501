<section class="bg-white card border-gris-claro flex flex-col h-full top-0 bottom-0"
         (mouseleave)="hover = false"
         [ngClass]="{
           'w-60': !oculto,
           'absolute z-50 w-64': oculto && hover,
           'w-12': oculto && !hover
           }">
  <div class="w-full">
    <div class="h-full w-full items-center flex px-2"
         [ngClass]="{
           'flex-col flex-col-reverse pt-1': oculto && !hover
           }">
      <div class="w-full flex flex-grow rounded hover:bg-gris-claro px-2 cursor-pointer"
           *ngIf="!oculto || hover">
        <div class="flex-grow self-center">DECET</div>
        <div class="icon">
          <i class="fas fa-caret-down"></i>
        </div>
      </div>
      <button class="icon"
              pTooltip="Dashboard" tooltipPosition="bottom" showDelay="100">
        <i class="fas fa-home"></i>
      </button>
      <button class="icon"
              (click)="cambiarTamano();$event.stopPropagation()"
              pTooltip="Menu" tooltipPosition="bottom" showDelay="100">
        <i class="fas fa-bars"></i>
      </button>
    </div>
  </div>
  <div class="h-12" *ngIf="!oculto">
    <h1 class="typo-subhead --text-clip opacity-60 px-4">Usuarios</h1>
  </div>

  <div class="overflow-auto h-full pt-1"
       (mouseenter)="hover = true">
    <auth-sesion-current-menu
      *ngFor="let item of secciones"
      [item]="item"
      [hover]="hover"
      [oculto]="oculto"></auth-sesion-current-menu>
  </div>
  <div class="w-full bg-white">
    <hr class="w-11/12 mx-auto">
    <div class="h-12 w-full items-center flex px-2" *ngIf="!oculto || hover">
      <div class="flex w-full rounded hover:bg-gris-claro px-2 items-center gap-2 cursor-pointer">
        <div class="flex-1 --text-clip">Cuenta chalalalalalalaa</div>
        <div class="flex-1 --text-clip text-gris">brunosalazarac@gmail.com</div>
        <div class="icon">
          <i class="fas fa-caret-down"></i>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center my-2" *ngIf="oculto && !hover">
      <div class="avatar h-7 w-7 px-2">HO</div>
    </div>
    <hr class="w-11/12 mx-auto" *ngIf="oculto && !hover">
    <div class="w-full items-center flex px-2" [ngClass]="{'h-12': !oculto || hover, 'flex-col': oculto && !hover}">
      <div class="" [ngClass]="{'flex-col flex-col-reverse h-auto': oculto && !hover, 'flex-grow': !oculto || hover}">
        <button class="icon" pTooltip="Mi cuenta" tooltipPosition="top" showDelay="500">
          <i class="fas fa-user"></i>
        </button>
        <button class="icon" pTooltip="Agregar usuarios" tooltipPosition="top" showDelay="500">
          <i class="fas fa-users"></i>
        </button>
        <button class="icon" pTooltip="Configuración" tooltipPosition="top" showDelay="500">
          <i class="fas fa-cog"></i>
        </button>
        <button class="icon" pTooltip="Notificaciones" tooltipPosition="top" showDelay="500">
          <i class="fas fa-bell"></i>
        </button>
        <button class="icon" pTooltip="Buscar" tooltipPosition="top" showDelay="500">
          <i class="fas fa-search"></i>
        </button>
      </div>
      <button class="icon"
              (click)="cambiarTamano();$event.stopPropagation()"
              pTooltip="Menu" tooltipPosition="bottom" showDelay="100">
        <i class="fas" [ngClass]="{'fa-arrow-right': oculto, 'fa-arrow-left': !oculto}"></i>
      </button>
    </div>
  </div>
</section>
