import { Component } from '@angular/core';
import { AuthSesionLoginService } from '../../apis/login.service';
import { ICredecial, ICredecialRespuesta, IUsuarioLogin } from '../../../../../types';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'auth-sesion-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthSesionLoginComponent {
  error = null;
  procesando: boolean;
  credencial: ICredecial;
  respuesta: ICredecialRespuesta;
  form: unknown;
  user: IUsuarioLogin;

  constructor(
    private sesion: AuthSesionLoginService,
    private location: Location) {
    this.procesando = false;
    this.credencial = { username: '', password: '' };
  }

  async aceptar(): Promise<void> {
    if (this.procesando) {
      return;
    }

    this.error = null;
    this.procesando = true;

    this.sesion.auth(this.credencial)
      .pipe(
        map((response) => {
          this.respuesta = response;
          this.procesando = false;
          this.sesion.setSession(response)
          this.verificar();
          return response
        }),
        catchError(({ error }) => {
          this.procesando = false;
          this.error = error.error || error;
          return of(error)
        })
      )
      .subscribe()
  }

  verificar(): void {
    this.sesion.current().subscribe(data => {
      this.location.back()
      this.user = data;
    })
  }

}
