import { Component } from '@angular/core';
import { AuthSesionReestablecerService } from '../../apis/reestablecer.service';
import { IReestablecer } from '../../../../../types';

@Component({
  selector: 'auth-sesion-reestablecer',
  templateUrl: './reestablecer.component.html',
  styleUrls: ['./reestablecer.component.scss'],
})
export class AuthSesionReestablecerComponent {
  completado: boolean;
  error: unknown;
  procesando: boolean;
  data: IReestablecer;

  constructor(private reestablecerService: AuthSesionReestablecerService) {
    this.completado = false;
    this.error = null;
    this.procesando = false;
    this.data = { email: '' };
  }

  reestablecer(): void {
    if (this.procesando) {
      return;
    }

    this.error = null;
    this.procesando = true;

    this.reestablecerService.post(this.data).toPromise()
      .then(() => {
        this.completado = true;
        this.procesando = false;
      })
      .catch((error) => {
        this.error = error;
        this.procesando = false;
      });
  }
}
