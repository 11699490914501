<!--<app-header>-->
  <div class="toolbar-container">
    <div class="toolbar-container-header">
      <div class="toolbar-header">
        <div class="__header-title typo-title">
        </div>
      </div>
    </div>
  </div>
<!--</app-header>-->

<!--<app-content>-->
  <div class="text-align-center">
    <h1 class="typo-display --margin-xl">
      NO AUTORIZADO</h1>
    <div class="--margin-lg">
      <div class="">No cuenta con permisos, por favor si esto es un error contactece con su Administrador
      </div>
    </div>
    <button class="p-button --margin-md"
      type="button"
      routerLink="/dashboard">
      Acceder al sistema
    </button>
  </div>
<!--</app-content>-->

