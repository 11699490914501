<!-- Root element for center items -->
<div class="flex flex-col h-screen">
  <!-- Auth Card Container -->
  <div class="grid place-items-center mx-2 sm:my-auto">
    <!-- Auth Card -->
    <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12
            px-6 py-10 sm:px-10 sm:py-6
            bg-white rounded-lg shadow-md lg:shadow-lg">

      <!-- Card Title -->
      <h2 class="text-center font-semibold text-3xl lg:text-4xl text-gray-800">
        Inicia Sesión
      </h2>

      <form  [name]="form"
        (ngSubmit)="aceptar()" class="mt-10">
        <!-- Email Input -->
        <input
          id="email"
          type="email"
          name="username"
          [ngClass]="{'form-invalid':error}"
          placeholder="Correo / Usuario"
          [(ngModel)]="credencial.username"
          autocomplete="email"
          class="block w-full py-3 px-1 mt-2
                    text-gray-800 appearance-none
                    border-b-2 border-gray-100
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
          required />

        <!-- Password Input -->
        <input
          id="password"
          type="password"
          name="password"
          placeholder="password"
          [(ngModel)]="credencial.password"
          autocomplete="current-password"
          class="block w-full py-3 px-1 mt-2 mb-4
                    text-gray-800 appearance-none
                    border-b-2 border-gray-100
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
          required />
        <span *ngIf="error" class="text-danger block">{{error}}</span>
        <a class="texto-pequeno" >Olvide mi contraseña</a>
        <!-- Auth Buttton -->
        <button type="submit"
          class="w-full py-3 mt-10 bg-gray-800 rounded-sm
                    font-medium text-white uppercase
                    focus:outline-none hover:bg-gray-700 hover:shadow-none">
        Iniciar Sesión
        </button>


      </form>
    </div>
  </div>
</div>
