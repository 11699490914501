import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { BackToTopComponent } from './back-to-top/back-to-top.component'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component'
import { NavbarComponent } from './navbar/navbar.component'
import { TelephonePipe } from './telephone.pipe'
import { DialogModule } from 'primeng/dialog'
import { ButtonModule } from 'primeng/button'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AuthSesionModule } from '../../../../../libs/auth/src/lib/sesion/sesion.module'
import { InicioSesionComponent } from '../inicio-sesion/inicio-sesion.component'

@NgModule({
  declarations: [
    BackToTopComponent,
    NavbarComponent,
    NavbarMenuComponent,
    HeaderComponent,
    FooterComponent,
    TelephonePipe,
    InicioSesionComponent,
  ],
  imports: [CommonModule, FormsModule, FontAwesomeModule, DialogModule, ButtonModule, RouterModule, AuthSesionModule],
  exports: [
    BackToTopComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    TelephonePipe,
    InicioSesionComponent,
    NavbarMenuComponent,
  ],
})
export class SharedModule {}
