<section class="contenedor color-texto-1" *ngIf="contacto | async as contacto">
  <p class="anuncio">
    <span>¿Estás buscando a alguien que te ayude?</span>
    <span class="titulo color-acento">¡Permítenos asesorarte!</span>
    <span class="color-texto-light">
      Llama ahora: <a href="tel:{{ contacto.telefono }}">{{ contacto.telefono | telephone }}</a>
    </span>
  </p>
  <hr class="divisor" />
  <ul class="contacto">
    <li>
      <fa-icon class="color-acento" [icon]="iconos.correo"></fa-icon>
      <a href="mailto:{{ contacto.correo }}">{{ contacto.correo }}</a>
    </li>
    <li>
      <fa-icon class="color-acento" [icon]="iconos.horario"></fa-icon>
      <span>{{ contacto.horario }}</span>
    </li>
  </ul>
</section>
<ul class="redes-sociales">
  <li *ngFor="let redSocial of redesSociales | async">
    <a [href]="redSocial.url">
      <fa-icon class="icono color-texto-light" [icon]="iconos[redSocial.icono]" size="2x"></fa-icon>
      <span class="sr-only">{{ redSocial.titulo }}</span>

    </a>
  </li>
</ul>
<rcco-contacto-suscribete></rcco-contacto-suscribete>
