import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import data from './data.json'

export interface Nosotros {
  items: NosotrosItem[]
}

export interface NosotrosItem {
  titulo: string
  descripcion: string
  imagen: Imagen
}

export interface CategoriaServicio {
  codigo: string
  titulo: string
  descripcion: string
  imagen: Imagen
  servicios: Servicio[]
}

export interface Imagen {
  url: string
  descripcion: string
}

export interface Servicio {
  titulo: string
  descripcion: string
  imagen: Imagen
  introduccion?: string
}

export interface Blogs {
  codigo: string
  titulo: string
  imagen: Imagen
  item: BlogsItems[]
  multimedia?: any[]
}

export interface BlogsItems {
  titulo: string
  descripcion: string
  descripcion1: string
  descripcion2: string
  descripcion3: string
  descripcion4: string
  descripcion5: string
  descripcion6: string
  descripcion7: string
  descripcion8: string
  descripcion9: string
  descripcion10: string
  descripcion11: string
  descripcion12: string
  descripcion13: string
  descripcion14: string
  imagen: Imagen
  autor: string
  leyenda: string
  departamento: string
  fecha: string
  avatar: Imagen
}

export interface Contacto {
  telefono: string
  correo: string
  horario: string
}

export interface RedSocial {
  icono: 'facebook'
  titulo: string
  url: string
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  nosotros(): Observable<Nosotros> {
    return of(data.nosotros)
  }

  servicios(): Observable<CategoriaServicio[]> {
    return of(data.servicios)
  }
  servicio(categoriaId: string): Observable<CategoriaServicio> {
    const categoria = data.servicios.find((item) => item.codigo === categoriaId)

    if (typeof categoria === 'undefined') throw new Error()

    return of(categoria)
  }

  blogs(): Observable<Blogs[]> {
    // @ts-ignore
    return of(data.blogsLista)
  }

  blog(blogId: string): Observable<Blogs> {
    const blog = data.blogsLista.find((item) => item.codigo === blogId)
    if (typeof blog === 'undefined') throw new Error()

    // @ts-ignore
    return of(blog)
  }

  introduccion(categoriaId: string): Observable<string | undefined> {
    const categoria = data.servicios.find((item) => item.codigo === categoriaId)

    if (!categoria) {
      throw new Error()
    }

    return of(categoria.introduccion)
  }

  contacto(): Observable<Contacto> {
    return of(data.contacto)
  }

  redesSociales(): Observable<RedSocial[]> {
    return of(data.redesSociales as RedSocial[])
  }
}
