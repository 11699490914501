import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { SharedModule } from '../shared/shared.module'
import { ContactoComponent } from './contacto.component'
import { SuscribeteComponent } from './suscribete/suscribete.component'
import { FormsModule } from '@angular/forms'
import { SuscripcionBrowserModule } from '@decet/suscripcion-browser'

@NgModule({
  declarations: [ContactoComponent, SuscribeteComponent],
  imports: [CommonModule, FormsModule, FontAwesomeModule, SharedModule, SuscripcionBrowserModule],
  exports: [ContactoComponent],
})
export class ContactoModule {}
