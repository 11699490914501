<div class="overflow-x-hidden bg-gray-100">
  <rcco-header class="encabezado">
    <img class="imagen" src="../../../assets/img/slider.webp" alt=""/>
    <div class="show-down">
      <h1 class="seccion">Aviso de privacidad</h1>
      <p class="descripcion" style="text-align: center;">
        Seguridad y eficacia en tus negocios.
      </p>
    </div>
  </rcco-header>
</div>

<main style="width: 80%; max-width: 900px;margin: 0 auto; margin-top: 2rem;">
<!--  <p style="text-align: center;"><strong>AVISO DE PRIVACIDAD</strong></p><br>-->
  <p style="text-align: justify;">Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos
    Personales en Posesión de los
    Particulares hacemos de su conocimiento que la sociedad CUIDAMOS TU EMPRESA, S.C., pone a su disposición el aviso de
    privacidad de conformidad con lo siguiente:</p><br>
  <p style="text-align: justify;">CUIDAMOS TU EMPRESA, S.C., es una persona jurídica constituida de conformidad con la
    legislación mexicana, quien es
    la responsable de dar tratamiento, recabar, proteger y preservar los datos personales, incluyendo aquellos
    denominados sensibles, patrimoniales y financieros, cuyo objeto principal es la consultoría, asesoría, planeación y
    administración en las áreas legales, contables, fiscales, corporativas e inmobiliarias, a todo tipo de empresas,
    personas físicas, morales, públicas, nacionales o extranjeras.</p><br>
  <p style="text-align: justify;">Que señala como domicilio para oír y recibir todo tipo de notificaciones el ubicado
    en: Calle Tacuba número 302,
    interior 2, Colonia Playas del sol, código postal 82148.</p><br>
  <ol start="1">
    <li><strong>I. Datos que pueden recabarse.</strong></li>
  </ol>
  <br>
  <p style="text-align: justify;">Los datos personales que se obtienen para cumplir con las finalidades establecidas en
    el presente aviso son:</p><br>
  <p class="underline" style="margin-left: 2rem;">
    De los titulares:</p><br>
  <ul style="margin-left: 3rem;">
    <li><strong>a)</strong> Nombre Completo</li>
    <li><strong>b)</strong> Denominación o razón social</li>
    <li><strong>c)</strong> Nacionalidad</li>
    <li><strong>d)</strong> Clave Única de Registro de Población (CURP)</li>
    <li><strong>e)</strong> Registro Federal de Contribuyentes (RFC)</li>
    <li><strong>f)</strong> Edad</li>
    <li><strong>g)</strong> Sexo</li>
    <li><strong>h)</strong> Fecha de nacimiento</li>
    <li><strong>i)</strong> Lugar de nacimiento</li>
    <li><strong>j)</strong> Domicilio particular</li>
    <li><strong>k)</strong> Número de teléfono particular</li>
    <li><strong>l)</strong> Número de celular</li>
    <li><strong>m)</strong> Correo electrónico</li>
    <li><strong>n)</strong> Firma autógrafa</li>
    <li><strong>o)</strong> Fotografía</li>
    <li><strong>p)</strong> Huella dactilar</li>
    <li><strong>q)</strong> Estado civil</li>
    <li><strong>r)</strong> Ocupación</li>
  </ul>
  <br>
  <p style="text-align: justify;">De conformidad con la legislación en la materia CUIDAMOS TU EMPRESA, S.C., considera
    que usted ha otorgado su
    consentimiento de manera tácita para el tratamiento respecto a sus datos personales anteriormente mencionados. Le
    hacemos de su conocimiento que podrá oponerse al tratamiento de sus datos personales en cualquier momento, mediante
    el procedimiento establecido en el punto número V del presente aviso de privacidad, denominado “Medios para ejercer
    los Derechos ARCO” (Acceso, Rectificación, Cancelación u Oposición). </p><br>
  <p class="underline"  style="margin-left: 2rem;">Datos Patrimoniales o Financieros: </p><br>

  <ul  style="margin-left: 3rem;">
    <li><strong>a)</strong> Número de tarjeta de crédito y/o débito</li>
    <li><strong>b)</strong> Bienes Patrimoniales</li>
    <li><strong>c)</strong> Cuentas bancarias</li>
    <li><strong>d)</strong> Estados de cuenta</li>
    <li><strong>e)</strong> Recibos de nómina</li>
    <li><strong>f)</strong> Crédito hipotecario (en caso de tener).</li>
  </ul>
  <br>

  <p style="text-align: justify;">En el caso de los Datos Financieros o Patrimoniales es obligatorio que, de conformidad
    con la legislación en la
    materia, nos otorgue su consentimiento expreso para darles tratamiento. Por lo que le solicitamos su firma en éste
    aviso de privacidad, así mismo en caso de que los datos Financieros o Patrimoniales le sean recabados a través de
    medios electrónicos, con la finalidad de adquirir algún producto o servicio de nuestra parte, el sólo hecho de que
    usted realice la compra del producto o servicio, se entenderá como su consentimiento con los términos y condiciones
    de éste aviso de privacidad.</p> <br>

  <ol start="2">
    <li><strong>II. Formas de obtener los datos personales.</strong></li>
  </ol>
  <br>

  <p style="margin-left: 2rem;">Dicha información podrá ser obtenida de la siguiente manera:</p><br>

  <ul style="margin-left: 3rem;">
    <li><strong>a)</strong> Por escrito</li>
    <li><strong>b)</strong> En forma verbal</li>
    <li><strong>c)</strong> A través de formularios en internet o electrónicos</li>
    <li><strong>d)</strong> Mediante servicios telefónicos</li>
    <li><strong>e)</strong> Cualquier otra forma permitida por la ley</li>
  </ul>
  <br>
  <p style="text-align: justify;">Tal información podrá ser recabada directamente de su titular o a través de un
    tercero, misma que nos permitirá
    ofrecer, prestar y/o mejorar los productos o servicios que se le brinden.</p>
  <br>

  <ol start="3">
    <li><strong>III. Uso de los Datos Personales.</strong></li>
  </ol>
  <br>
  <p>Los Datos Personales que se recaben se utilizarán para dar cumplimiento a los siguientes fines:</p><br>
  <p class="underline" style="margin-left: 2rem;">Finalidades principales:</p><br>

  <ul style="margin-left: 3rem;">
    <li><strong>a)</strong> Confirmar su identidad</li>
    <li><strong>b)</strong> Cumplir con los requerimientos legales que sean aplicables</li>
    <li><strong>c)</strong> Verificar la información que nos proporciona</li>
    <li><strong>d)</strong> Dar seguimiento al proceso de cobranza</li>
    <li><strong>e)</strong> Para enviarle facturas</li>
    <li style="text-align: justify;">f) En su caso, cuando los datos personales se integren a un documento legal, podrá transferirse a entidades de
      gobierno
    </li>
    <li>g) Cualquier otra finalidad compatible o análoga a los fines previamente establecidos</li>
  </ul>
  <br>
  <p class="underline" style="margin-left: 2rem;">Finalidades Secundarias: </p><br>

  <p style="text-align: justify;">De manera adicional, utilizaremos su información personal para las siguientes
    finalidades secundarias,<strong>que
      no son necesarias</strong> para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor
    atención:</p>
  <br>

  <ul style="margin-left: 3rem;">
    <li><strong>a)</strong> Circulares informativas</li>
    <li><strong>b)</strong> Evaluaciones de calidad de los productos o servicios que contrató</li>
    <li><strong>c)</strong> Para enviarle información comercial, servicios o promociones</li>
    <li><strong>d)</strong> Para invitarlo a eventos por realizar</li>
  </ul>
  <br>
  <p style="text-align: justify;">
    En caso de que no desee que sus datos personales sean tratados para estos fines secundarios, usted puede presentar
    desde este momento, un escrito dirigido a CUIDAMOS TU EMPRESA, S.C. en el domicilio señalado para tales efectos en
    el presente documento, informando tal situación.
  </p> <br>
  <p style="text-align: justify;">La negativa para el uso de sus datos personales para estas finalidades, no podrá ser
    un motivo para que le neguemos
    los productos o servicios que solicita o contrata con nosotros.</p>
  <br>
  <ol start="4">
    <li><strong>
      IV. Limitaciones en el uso o divulgación de su información personal.
    </strong></li>
  </ol>

  <p style="text-align: justify;">Con la finalidad de resguardar de la mejor manera posible su información CUIDAMOS TU
    EMPRESA, S.C., se compromete a
    tratar sus datos personales bajo estrictas medidas de seguridad para garantizar su confidencialidad. Se han
    establecido estándares de seguridad en las materias administrativa, legal y se han aplicado medidas tecnológicas
    para evitar que terceros no autorizados se apoderen de sus datos personales</p><br>
  <p style="text-align: justify;">Cabe mencionar que el titular de los datos personales, tiene la posibilidad de iniciar
    un procedimiento de Acceso,
    Rectificación, Cancelación u Oposición (Por sus siglas ARCO), para limitar el uso o divulgar sus datos de
    conformidad con el capítulo V del presente aviso de privacidad. </p><br>
  <p style="text-align: justify;">Además, existe un medio mediante el cual usted puede limitar la publicidad o llamadas
    telefónicas denominado
    Registro Público para Evitar Publicidad, el cual es proporcionado por la Procuraduría Federal del Consumidor y lo
    puede consultar en la página de internet: <strong><a href="https://repep.profeco.gob.mx/.">https://repep.profeco.gob.mx/. </a></strong>
  </p><br>

  <ol start="5">
    <li><strong>
      V. Medios para ejercer los Derechos ARCO (Acceso, Rectificación, Cancelación u Oposición) de los datos personales.
    </strong></li>
  </ol>
  <br>

  <p style="text-align: justify;">Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el
    tratamiento de sus datos
    personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o
    concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus
    datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento será
    un impedimento para entregar el producto o prestar el servicio que nos solicitó, o bien, la conclusión de su
    relación con la empresa.</p><br>
  <p style="text-align: justify;">El titular podrá ejercer sus derechos de rectificación, acceso, cancelación u
    oposición al tratamiento de sus
    datos, por sus siglas ARCO, presentando una solicitud por escrito de conformidad con el siguiente
    procedimiento: </p><br>
  <p style="text-align: justify;">Realizar su solicitud enviando un correo electrónico a la siguiente dirección
    <strong>contacto@rccoabogados.com.mx</strong> dirigido
    a CUIDAMOS TU EMPRESA, S.C., quien turnará su solicitud al responsable correspondiente. Los datos y documentación
    que debe anexar a su solicitud son:</p><br>

  <ul style="margin-left: 3rem;">
    <li style="text-align: justify;"><strong>a)</strong> El nombre del titular de los datos personales, su domicilio y un medio alterno para comunicarle la respuesta
      a su solicitud;
    </li>
    <li style="text-align: justify;"><strong>b)</strong> Los documentos que acrediten la identidad del titular de los datos personales o, en su caso, si lo hace un
      tercero acreditar la representación legal;
    </li>
    <li style="text-align: justify;"><strong>c)</strong> La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los
      derechos ARCO;
    </li>
    <li style="text-align: justify;"><strong>d)</strong> Cualquier otro elemento o documento que facilite la localización de sus Datos Personales;</li>
    <li style="text-align: justify;"><strong>e)</strong> Para las solicitudes de rectificación de datos personales, el titular deberá indicar las modificaciones a
      realizarse y aportar la documentación que sustente su petición.
    </li>
    <li style="text-align: justify;"><strong>f)</strong> En caso de que se aprecie que la documentación tenga tachaduras, esté alterada o se tenga duda razonable de
      ser apócrifa, que no sea el titular, o no ser el legítimo representante, CUIDAMOS TU EMPRESA, S.C. se reserva el
      derecho de solicitar cualquier otro documento para comprobar y acreditar la titularidad de los datos, por lo que
      se le podrá solicitar que presente la documentación en original o copias certificadas en la oficina del
      responsable.
    </li>
  </ul>
  <br>
  <p style="text-align: justify;">Si llegare a faltar alguna información en su solicitud, se le solicitará que subsane
    la omisión.</p><br>
  <p>Si cumpliera con todas las formalidades requeridas para dicho trámite, se dará respuesta en un plazo máximo de
    veinte días hábiles, contados a partir de la fecha en que se recibió su petición o a partir de la fecha en que haya
    subsanado en su totalidad las omisiones. El plazo antes referido podrá ser ampliado una sola vez por un periodo
    igual, siempre y cuando así lo justifiquen las circunstancias del caso. De conformidad con el artículo 32 de la ley
    de la materia.</p><br>

  <ol start="6">
    <li><strong>
      VI. Transferencias de Datos Personales.
    </strong></li>
  </ol>
  <br>
  <p style="text-align: justify;">Los datos personales sólo son tratados por el personal adscrito a esta empresa, a
    efecto de integrar el expediente
    respectivo y en su caso, elaborar el o los contratos correspondientes, por lo cual sus datos personales no se
    transfieren a ningún tercero ajeno a ella.</p><br>
  <p style="text-align: justify;">Sólo se podrán hacer transferencias sin su consentimiento de acuerdo a lo previsto en
    el artículo 37 de la Ley
    Federal de Protección de datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en
    los términos que fija la referida ley, por lo que a continuación señalamos los supuestos que ahí se indican: </p>
  <br>

  <ul style="margin-left: 2rem;">
    <li><strong>a)</strong> Cuando la transferencia esté prevista en una Ley;</li>
    <li><strong>b)</strong> Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia
      sanitaria, tratamiento médico o la gestión de servicios sanitarios;
    </li>
    <li><strong>c)</strong> Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control
      común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo Grupo del responsable que opere
      bajo los mismos procesos y políticas internas;
    </li>
    <li><strong>d)</strong> Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del
      titular, por el responsable y un tercero;
    </li>
    <li><strong>e)</strong> Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para
      la procuración o administración de justicia;
    </li>
    <li><strong>f)</strong> Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso
      judicial; y
    </li>
    <li><strong>g)</strong> Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el
      responsable y el titular.
    </li>
  </ul>
  <br>
  <ol start="7">
    <li><strong>
      VII. Cambios al Aviso de Privacidad.
    </strong></li>
  </ol>
  <br>
  <p style="text-align: justify;">El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones
    por lo cual la empresa se compromete a proporcionarle la información en las oficinas ubicadas en Calle Tacuba número
    302, interior 2, Colonia Playas del sol, código postal 82148.</p><br>
  <p style="text-align: justify;">Toda modificación se notificará mediante un aviso en el portal de internet de la
    empresa, denominado: <strong><a
      href="www.rccoabogados.com.mx">www.rccoabogados.com.mx</a></strong> propiedad de la empresa.</p><br>

  <ol start="8">
    <li><strong>
      VIII. Contáctanos.
    </strong></li>
  </ol>
  <br>
  <p>Si usted tiene alguna duda sobre el presente Aviso de Privacidad, puede dirigirla a:</p><br>
  <ul style="margin-left: 3rem;">
    <li><strong>-</strong> La dirección de correo electrónico: <strong>contacto@rccoabogados.com.mx</strong></li>
    <li><strong>-</strong> Al teléfono: <strong>(669) 153-0597</strong></li>
  </ul>
  <br>
  <p style="text-align: justify;">Asimismo, ponemos a su disposición copia del presente Aviso de Privacidad en el
    domicilio ubicado en Calle Tacuba número 302, interior 2, Colonia Playas del sol, código postal 82148</p><br>
</main>


