<h1 class="titulo color-acento">Suscríbete</h1>
<p class="parrafo">Mantente al día con las novedades de nuestros productos y servicios</p>
<form class="formulario"
      name="form"
      #form="ngForm"
      autocomplete="off"
      (ngSubmit)="aceptar(form)">
  <input class="input"
         [pattern]="patron"
         name="correo"
         placeholder="Ingresa tu correo"
         required
         [(ngModel)]="correo">
  <button class="boton" type="submit" [disabled]="form.invalid">Suscribir</button>
</form>
<p class="mt-2 nota" *ngIf="exito"><small>¡Gracias! por suscribirse a nuestro newsletter en breve enviaremos un correo de bienvenida.</small></p>
<p class="nota color-texto-light">
  <small>Al suscribirse, aceptas el <a href="avisos-de-privacidad"><strong>Aviso de Privacidad</strong></a> y los <a
    href="#"><strong>Términos y Condiciones</strong></a>.</small>
</p>
