import { animate, style, transition, trigger } from '@angular/animations'
import { Component } from '@angular/core'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { BackToTopService } from './back-to-top.service'

@Component({
  selector: 'rcco-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class BackToTopComponent {
  icon = faChevronUp

  constructor(public backToTopService: BackToTopService) {}

  scrollToTop() {
    this.backToTopService.scrollTo(0)
  }
}
