import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISessionModulosPermisos } from '../../../../types';

@Injectable({
  providedIn: 'root',
})
export class AuthSesionModuloService {

  constructor(@Inject(HttpClient) private http: HttpClient) {
  }

  get(seccionId: number): Observable<ISessionModulosPermisos> {
    return this.http.get<ISessionModulosPermisos>(`/api/auth/sesion/modulo/${seccionId}`);
  }
}
