import { createReducer } from '@ngrx/store';

export const initialState = {
  sesion: {
    permisos: [],
  },
}

const posReducer = createReducer(
  initialState,
)

// tslint:disable-next-line:typedef
export function pos(state, action) {
  return posReducer(state, action)
}
