import { Pipe, PipeTransform } from '@angular/core'

const regexp = /^(.*?)(\d{3})(\d{4})(\d{3})$/

@Pipe({
  name: 'telephone',
})
export class TelephonePipe implements PipeTransform {
  transform(value: string): unknown {
    const match = value.match(regexp)

    if (!match) return value

    const [, prefix, ...n] = match

    return (prefix ? `(${prefix}) ` : '') + n.join('-')
  }
}
