import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthSesionLoginComponent } from './components/login/login.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthSesionAutorizacionComponent } from './components/autorizacion/autorizacion.component';
import { AuthSesionFinalizarComponent } from './components/finalizar/finalizar.component';
import { AuthSesionReestablecerComponent } from './components/reestablecer/reestablecer.component';
import { AuthSesionRegistrarComponent } from './components/registrar/registrar.component';
import { CheckboxModule } from 'primeng/checkbox';
import { AutSesionRoutingModule } from './sesion-routing.module';
import { AuthCurrentComponent } from './current/current.component';
import { AuthSesionModuloService } from '../usuarios/apis/modulo.service';
import { InterceptorProvider } from './interceptor/interceptor';
import { TooltipModule } from 'primeng/tooltip';
import { AuthSesionReestablecerCambiarService } from './apis/reestablecer/cambiar.service';
import { AuthSesionJwtService } from './apis/jwt.service';
import { AuthSesionLoginService } from './apis/login.service';
import { AuthSesionReestablecerService } from './apis/reestablecer.service';
import { MenuModule } from 'primeng/menu';
import { StoreModule } from '@ngrx/store';
import { permisos } from './store/permisos.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../../../apps/pos/src/environments/environment';
import { AuthSesionPermisosService } from './services/permisos.service';
import { AuthSesionCurrentMenuComponent } from './current/menu/menu.component';


@NgModule({
  declarations: [
    AuthSesionLoginComponent,
    AuthSesionAutorizacionComponent,
    AuthSesionFinalizarComponent,
    AuthSesionReestablecerComponent,
    AuthSesionRegistrarComponent,
    AuthCurrentComponent,
    AuthSesionCurrentMenuComponent,
  ],
  imports: [
    CommonModule,
    AutSesionRoutingModule,
    ProgressBarModule,
    CheckboxModule,
    FormsModule,
    RouterModule,
    TooltipModule,
    MenuModule,
    StoreModule.forFeature('sesion', permisos),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  exports: [
    AuthCurrentComponent
  ],
  providers: [
    AuthSesionModuloService,
    AuthSesionReestablecerCambiarService,
    AuthSesionJwtService,
    AuthSesionLoginService,
    AuthSesionReestablecerService,
    AuthSesionPermisosService,
    InterceptorProvider
  ]
})
export class AuthSesionModule {
}
