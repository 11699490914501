import { Component, HostBinding, HostListener } from '@angular/core'

@Component({
  selector: 'rcco-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  isOpen = false
  administrador = false

  @HostListener('window:keydown.esc', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.isOpen = false
    event.preventDefault()
  }

  @HostListener('document:click', ['$event'])
  public onClick() {
    if (this.isOpen) {
      this.isOpen = false
    }
  }

  @HostBinding('attr.open')
  get open() {
    return this.isOpen
  }

  toggle(event: MouseEvent) {
    this.isOpen = !this.isOpen
    event.stopPropagation()
  }
}
