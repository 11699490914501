import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rcco-avisos-privacidad',
  templateUrl: './avisos-privacidad.component.html',
  styleUrls: ['./avisos-privacidad.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AvisosPrivacidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
