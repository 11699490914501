import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'auth-sesion-current-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class AuthSesionCurrentMenuComponent implements OnInit {

  @Input() item
  menu = []///
  @Input() oculto!: boolean
  @Input() hover!: boolean
  expandir = false
  urlPrincipal = ''

  constructor(
    private route: Router,
    private routeActived: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.urlPrincipal = this.routeActived._routerState.snapshot.url.split('/')[1]
    this.menu = [
      {
        label: this.item.nombre,
        icon: this.item.icon,
        items: this.item.items.map((item) => ({
          label: item.descripcion,
          icon: item.icon,
          command: () => this.navegar(`${this.urlPrincipal}${item.url}`)
        }))
      }
    ]
  }

  navegar(url: string) {
    this.route.navigate([url])
  }

}
