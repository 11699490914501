import { createAction, props } from '@ngrx/store'

export const cargarPermisos = createAction(
  '[Agregar Administrador]',
  props<{ data: any }>(),
)

export const obtenerPermisos = createAction(
  '[Obtener Administradores]',
  props<{ data: any }>(),
)
